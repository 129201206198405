<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <b-form class="row" @submit="saveCategory" @reset="onReset">
              <div class="col-md-6">
                <b-form-group
                    id="input-group-1"
                    label="Tên danh mục(*):"
                    label-for="input-1"
                >
                  <b-form-input
                      id="input-1"
                      v-model="form.name"
                      required
                      placeholder="Tên danh mục"
                      maxlength="11"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                    id="input-group-0"
                    label="Icon danh mục (*):"
                    label-for="input-0"
                >
                  <div style="display: flex">
                    <div style="height: 38px; margin-right: 20px" v-if="form.icon.length >0">
                      <img
                          style="width: 38px; height: 38px"
                          :src="form.icon"
                          alt=""
                      />
                    </div>
                    <b-form-file
                        @change="onImageChange($event)"
                        placeholder="chọn icon"
                        type="file"
                        accept="image/*"
                        :required="form.icon.length > 0?false:true"

                    ></b-form-file>
                  </div>

                </b-form-group>
                <div class="form-inline" style="margin-bottom: 20px">
                  <input   id="stt" class="form-control" type="checkbox" :true-value="1" :false-value="0" value="1" v-model="form.status">
                  <label for="stt" style=" margin-left: 10px">
                    Hiển thị
                  </label>
                </div>

              </div>



              <div class="col-md-12">
                <b-button type="submit" variant="primary">{{
                    is_edit ? "Cập nhập" : "Xác nhận"
                  }}</b-button>
                <router-link v-if="is_edit" :to="'/campaign/category'">
                  <b-button variant="danger">Hủy bỏ</b-button>
                </router-link>
                <b-button v-else type="reset" variant="danger">Làm mới</b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";
import Multiselect from "vue-multiselect";
import Vue from "vue";
Vue.component("multiselect", Multiselect);
const CampaignRepository = RepositoryFactory.get("Campaign");
const WalletRunRepository = RepositoryFactory.get("WalletRun");

export default {
  name: "FormCategory",
  mixins: [Common],
  components: {
  },
  data() {
    return {
      form: {
        name: "",
        status: 1,
        icon: '',

      },
      is_edit: false,
    };

  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Quản lí danh mục ", route: "/campaign/category" },
      { title: this.is_edit ?"Sửa danh mục" : "Thêm danh mục" },
    ]);
  },
  methods: {
    onImageChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      let fileSize = files[0].size / 1024 / 1024; // in MiB
      if (fileSize> 2){
        this.$notify({
          group: "foo",
          type: "error",
          title:"Chọn ảnh không vượt quá 2MB",
        });
        return false;
      }
      this.images = files[0];
      this.uploadMedia();
    },
    uploadMedia: async function () {
      this.$bus.$emit("show-loading", true);
      const formData = new FormData();
      formData.append("file", this.images);

      WalletRunRepository.uploadMedia(formData)
          .then((response) => {
            this.$bus.$emit("show-loading", false);
            if (1 === response.data.error_code) {
              this.$notify({
                group: "foo",
                type: "error",
                title: response.data.message,
              });
            } else {
              this.form.icon = response.data.data.link;
            }
          })
          .catch((response) => {
            this.$bus.$emit("show-loading", false);
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          });
    },
    async loadCategory(id) {
      try {
        this.$bus.$emit("show-loading", true);
        let response = await CampaignRepository.getCategory(id);
        this.$bus.$emit("show-loading", false);
        let body = response.data;
        if (body.data) {
          this.form.status = body.data.status;
          this.form.name = body.data.name;
          if (body.data.icon!= null){
            this.form.icon = body.data.icon;
          }
        }
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },
    async getCodes() {
      try {
        this.$bus.$emit("show-loading", true);
        let response = await CampaignRepository.getOptions();
        this.$bus.$emit("show-loading", false);
        let body = response.data;
        if (body.data) {
          this.options = body.data.mission_code

          let that = this;
          this.options.forEach((item) => {
            that.mission_type_option.push(item);
          });
          body.data.bank_direct_link.forEach((item) => {
            that.bank_option.push(item);
          });
          body.data.payment_service.forEach((item) => {

            that.payment_option.push(item);
          });
        }
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },
    saveCategory: async function () {


      let params = this.form;
      if (this.is_edit) {
        this.updateCategory(params);
        return false;
      }

      CampaignRepository.createCategory(params)
        .then((response) => {
          this.$bus.$emit("show-loading", false);
          if (1 === response.data.error_code) {
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          } else {
            this.$notify({
              group: "foo",
              type: "success",
              title: response.data.message,
            });
            this.$router.push({ name: "campaign_category_challenges" });
          }
        })
        .catch((response) => {
          this.$bus.$emit("show-loading", false);
          this.$notify({
            group: "foo",
            type: "error",
            title: response.data.message,
          });
        });
    },
    updateCategory: async function (params) {
      this.$bus.$emit("show-loading", true);

      CampaignRepository.updateCategory(this.$route.params.id, params)
        .then((response) => {
          this.$bus.$emit("show-loading", false);
          if (1 === response.data.error_code) {
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          } else {
            this.$notify({
              group: "foo",
              type: "success",
              title: response.data.message,
            });
            this.$router.push({ name: "campaign_category_challenges" });
          }
        })
        .catch((response) => {
          this.$bus.$emit("show-loading", false);
          this.$notify({
            group: "foo",
            type: "error",
            title: response.data.message,
          });
        });
    },
    onReset(evt) {
      evt.preventDefault();
      this.form = {
        name: "",
        status: 1,
        icon: '',
      }

    },
  },
  created() {
    if (this.$route.params.id) {
      this.is_edit = true;
      this.loadCategory(this.$route.params.id);
    }
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.title-block{
  color: #FF0000;
  font-size: 18px;
  font-weight: bold;
}
</style>
